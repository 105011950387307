import React from "react";
import { motion } from "framer-motion";
import { PlatformData } from "../../../../utils/data/platform";

const modalVariants = {
  hidden: {
    opacity: 0,
    scale: 0.9,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      type: "spring",
      damping: 25,
      stiffness: 300,
    },
  },
};

export default function AppsModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <motion.div
      className="fixed inset-0 z-50 bg-black bg-opacity-80 flex items-start justify-center overflow-y-auto"
      variants={modalVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="relative rounded-lg shadow-lg w-full max-w-7xl p-6">
        <button
          onClick={onClose}
          className="absolute top-4 left-4 text-white hover:text-gray-700 text-5xl"
        >
          &times;
        </button>
        <div>
          <h1 className="heading2_semibold text-white text-center text-4xl mb-5">
            Platforms
          </h1>
        </div>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {PlatformData[0].potentials.map((item, index) => (
            <div
              key={index}
              className="relative p-6 w-full flex flex-col rounded-lg bg-white shadow-card pt-10" // Added pt-10 here
            >
              <div className="absolute top-3 right-3 flex space-x-2">
                {item.networks.map((network, idx) => (
                  <img
                    key={idx}
                    src={network}
                    className="w-7 h-7"
                    alt={`Network ${idx}`}
                  />
                ))}
              </div>
              <div className="flex-grow flex flex-col justify-center">
                <img
                  src={item.img}
                  alt={item.title}
                  className="w-full mb-4 max-h-28 object-contain"
                />
                <h6 className="text-lg font-semibold text-gray-800 mb-2">
                  {item.title}
                </h6>
                <p className="text-gray-400 text-sm mb-4">{item.subtitle}</p>
              </div>
              <a
                href={item.Link}
                target="_blank"
                rel="noopener noreferrer"
                className="btn w-full bg-blue-500 text-white py-2 px-4 rounded text-center"
              >
                {item.button}
              </a>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
}
